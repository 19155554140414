/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useMemo, } from 'react'
import HeaderBlock from './HeaderBlock'
import CardDisciplineDetail from '../../CardDisciplineDetail/CardDisciplineDetail'
import AddTrackPopup from '../../AddDisciplinePopup/AddTrackPopup'
import { useSignUpTrack, } from '../../../../../../gql/hooks/useMyEducation'
import { useSelector, } from 'react-redux'
import { useLocation, } from 'react-router-dom'
import { getSemester, } from '../../../../../../Utils/functions'
import { useAppContext, } from '@/app/providers/AppProvider'


const TrackHeader = ({
  role,
  type,
  attachedFiles,
  res,
  id,
  disciplinesList,
  is_active,
  is_signed,
}: any) => {
  const [
    isSucces,
    setIsSuccess,
  ] = useState(false)
  const [
    isPopup,
    setIsPopup,
  ] = useState(false)
  const [
    isSignDisabled,
    setIsSignDisabled,
  ] = useState(false)

  const location = useLocation()

  const trackName = new URLSearchParams(location.search).get('name')


  const trackDisciplines = useMemo(() => {
    return disciplinesList.map((i: any) => <CardDisciplineDetail
      name={ i.discipline } key={ i.discipline_id }
      disciplineId={ i.discipline_id }
      type="Дисциплина"
      moderMode={ role === 'osrv-moder' }
      trackName={ trackName }
      id={ id }
    />)
  }, [
    disciplinesList,
    role,
    id,
    trackName,
  ])

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)
  const nameSpec = new URLSearchParams(location.search).get('namespec')

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studInfo = useSelector(state => ((role === 'student' || role === 'student-rakus') ? state.prof.studentInfo?.filter((i: any) => i.namespec === nameSpec) : false))

  const {
    department_name,
    eform,
    nameprof,
    namespec,
    course,
    group,
    recordbook_guid,
  }: any = studInfo ? studInfo[0] : {}

  const [
    esSignUpTrack,
  ] = useSignUpTrack()

  const { openToast, } = useAppContext()

  /* const [esUpdateDisciplineStats] = useUpdateStats({
    ref_id: +id,
    namespec,
    type: "miner",
    counter: "in"
  }) */


  const { addNotification, } = useAppContext()
  const handleParticipate = () => {
    setIsSignDisabled(true)
    esSignUpTrack({
      variables: {
        i: {
          track_id: +id,
          participants: [
            {
              id: currentUserId,
              recordbook: recordbook_guid,
              department_name,
              eform,
              nameprof,
              namespec,
              course,
              group,
            },
          ],
          semester: getSemester(+course),
        },
      },
    })
      .then(res => {
        addNotification?.(
          'Мое обучение',
          [
            currentUserId,
          ],
          { message: `Вы записались на трек "${ trackName }"`, },
          { path: '/learning-pathway', }
        )
        setIsSignDisabled(false)
        setIsSuccess(!isSucces)
        /*   esUpdateDisciplineStats()
          .then(() => {

          })
          .catch((e) => console.log(e)) */
      })
      .catch(e => {
        openToast?.({ message: 'Произошла ошибка, попробуйте повторить позднее или обратитесь в поддержку', })
      })
  }


  return <>
    <HeaderBlock role={ role } type={ type } track_id={ +id } attachedFiles={ attachedFiles } res={ res }
      /* handleSignOut={handleSignOut} */ handleParticipate={ handleParticipate } isSucces={ isSucces }
      is_active={ is_active } is_signed={ is_signed } isDisable={ isSignDisabled } name={ res?.name } max_participants={ res?.max_participants } sign_count={ res?.sign_count }
      setIsSuccess={ setIsSuccess } isPopup={ isPopup } setIsPopup={ setIsPopup } trackDisciplines={ trackDisciplines } />
    { res && <AddTrackPopup isOpen={ isPopup } handlePopup={ () => setIsPopup(!isPopup) }
      type={ type } title="Редактировать трек" data={ res } /> }
  </>
}

export default TrackHeader
