/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './HeaderBlock.less'
import { IconPaperclip, } from '@tabler/icons'
import clsx from 'clsx'
import { useState, } from 'react'
import AttachedFiles from '../../../../../common/AttachedFiles/AttachedFiles'
import ButtonSimple, { IconComponent, } from '../../../../../common/ButtonSimple/ButtonSimple'
import LinkButton from '../../../../../common/LinkButton/LinkButton'
import InputTextDeprecated from '../../../../../common/InputTextDeprecated/InputTextDeprecated'
import Popup from '../../../../../common/Popup/Popup'
import useRedirect from '@/legacy/hooks/useRedirect'


const HeaderBlock = ({
  role,
  trackDisciplines,
  type,
  attachedFiles,
  name,
  handleParticipate,
  handleSignOut,
  setIsSuccess,
  isSucces,
  isPopup,
  setIsPopup,
  is_active,
  is_signed,
  level,
  description,
  isDisable = false,
  track_id,
  sign_count,
  max_participants,
}: any) => {
  const redirect = useRedirect()

  const [
    isEditMode,
    setIsEditMode,
  ] = useState(false)
  const [
    descriptionText,
    setDescriptionText,
  ] = useState(initialText)

  const buttonsData = {
    'Трек': {
      textButton: 'Записаться на трек',
      /* onClick: () => setIsSuccess(true) */
    },
    'Факультатив': {
      textButton: 'Записаться на факультатив',
    },
    'Электив': {
      textButton: 'Записаться на электив',
    },
    'Курсы': {
      textButton: 'Оставить заявку',
    },
  }
  

  return (
    <>
      <div className={ clsx({
        'detail-page-header__title-wrapper': true,
        'detail-page-header__title-wrapper_courses': type === 'Курсы',
      }) }
      >
        <h1 className="detail-page-header__title">{ name }</h1>

        { isEditMode || role !== 'osrv-moder' ? false : (
          <div className="trackEditWrap">
            <LinkButton
              type="edit"
              onClickHandler={ () => (type === 'Трек' ? redirect(`/edit-track?id=${ track_id }&name=${ name }`) : setIsPopup(!isPopup)) }
            />
            { type === 'Трек' && <LinkButton
              type="editAllow"
              onClickHandler={ () => setIsPopup(!isPopup) }
            /> }
          </div>
        ) }

        { type === 'Трек' ? (
          <p className="detail-page-header__subtitle">
            Трек содержит следующие дисциплины:
          </p>
        ) : false }
      </div>

      { type !== 'Трек' ? (
        <div className='detail-page-header__description-wrapper'>
          <p className='learning-pathway-tab__subtitle'>
            Описание
          </p>
          { isEditMode ?
            <p className='learning-pathway-tab__description'>
              <textarea
                className='learning-pathway-tab__description-textarea'
                // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
                rows="5"
                value={ descriptionText }
                onChange={ event => setDescriptionText(event.target.value) }
              >
              </textarea>
            </p> : <p className='learning-pathway-tab__description' dangerouslySetInnerHTML={ { __html: description, } } /> }

          { type !== 'Факультатив' && <div
            className={ clsx({
              'learning-pathway-tab__attached-files-wrapper': true,
              'learning-pathway-tab__attached-files-wrapper_edit': isEditMode,
            }) }
          >
            { (isEditMode && type === 'Трек') ? (
              <ButtonSimple
                styles={ {
                  float: 'left',
                  marginRight: '1rem',
                } }
                value={
                  <IconComponent
                    text="Добавить аннотацию (.doc)"
                    Icon={ IconPaperclip }
                  />
                }
                variant="alone"
              />
            ) : false }

            { attachedFiles?.length > 0 ? (
              <AttachedFiles
                isDeleteFilesAllowed={ isEditMode }
                fileList={ attachedFiles }
              />
            ) : false }
          </div> }

          { isEditMode ? (
            <div className='learning-pathway-tab__attached-buttons-wrapper'>
              <ButtonSimple value="Отменить" variant="groupe" />
              <ButtonSimple value="Сохранить изменения" active />
            </div>
          ) : false }
        </div>
      ) : false }

      { type === 'Трек' ? (
        <div className='detail-page-header__cards-wrapper'>
          { trackDisciplines }
        </div>
      ) : false }

      <div className={ clsx({
        'detail-page-header__button-wrapper': true,
        'detail-page-header__button-wrapper_courses': type === 'Курсы',
      }) }
      >
        { type === 'Курсы' ? (
          <div className='detail-page-header__price-wrapper'>
            <span className='detail-page-header__price'>
              { isEditMode ? (
                <InputTextDeprecated styles={ {
                  marginRight: '1rem',
                } } />
              ) : 500 } руб.
            </span>

            <span className='detail-page-header__price-description'>
              Стоимость на момент заключения договора может измениться
            </span>
          </div>
        ) : false }

        { ((role === 'student') && !level && is_active && (max_participants < 0 || !max_participants || max_participants > sign_count)) ? (
          (is_signed ? (type === 'Факультатив' ? <ButtonSimple
            value="Отписаться от факультатива"
            onClickHandler={ handleSignOut }
            variant="fill"
            isDisabled={ isDisable }
          /> : 'Вы записаны') :
            <ButtonSimple
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              value={ buttonsData[type].textButton }
              onClickHandler={ handleParticipate }
              variant="fill"
              isDisabled={ isDisable }
            />)
        ) : false }
        { ((max_participants <= sign_count) && (max_participants > 0) && role !== 'osrv-moder') ? 'Набранно максимальное количество участников' : false }

        { (!is_active && role !== 'osrv-moder' && (type === 'Факультатив' || type === 'Электив' || type === 'Трек')) && (
          <p
            style={ {
              color: 'red',
              fontSize: '20px',
              marginLeft: '10px',
            } }
            className='learning-pathway-tab__subtitle'
          >
            Запись закрыта
          </p>
        ) }
      </div>
      <Popup
        isOpen={ isSucces }
        handlePopup={ () => setIsSuccess(false) }
      >
        <div style={ {
          display: 'flex',
          flexDirection: 'column',
        } }>
          <h3 className="PopupTitle-K">{ `Вы записались на ${ type } "${ name }"` }</h3>

          <ButtonSimple value="Понятно" variant="fill" onClickHandler={ () => setIsSuccess(false) } styles={ {
            width: 'fit-content',
            alignSelf: 'end',
          } } />
        </div>
      </Popup>
    </>
  )
}


const initialText = 'Критическое мышление является обязательной составной частью Soft Skills, формирование которых необходимо для повышения конкурентоспособности современных выпускников вуза. В данной дисциплине рассматриваются психологические аспекты формирования и развития критического мышления. Дисциплина рассчитана на получение практических умений и навыков использования инструментов критического мышления во всех сферах жизни человека'


export default HeaderBlock
