import clsx from 'clsx'
import './FilterPopup.less'
import { Image, } from '@/shared/ui/Image'

export function FilterPopup({
  items,
  isOpen,
  handler,
  onClickAway,
  type,
  clearField,
  style,
  className,
}: any): JSX.Element {
  function handleClick(item: any) {
    handler(item)
    if (clearField) {
      clearField()
    }
    if (onClickAway) {
      onClickAway()
    }
  }

  if (!isOpen) {
    return <></>
  }

  return (
    <ul className={clsx('projPopup', className)} style={style}>
      {items?.length ? (
        items.map((item: any, index: number) => (
          <li
            key={item.id || index}
            onClick={() => handleClick(item)}
            className={clsx('projPopupItem', type === 'person' && 'persons')}
          >
            {type === 'person' ? (
              <>
                <Image
                  className="person-image"
                  id={item.id}
                  style={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '20px',
                  }}
                />
                <div className="consultant">
                  <p>{item.full_name}</p>
                  <div className="mail">
                    {item.contacts
                      .filter((con: any) => con.type === 'Корпортативная почта')
                      .map((con: any) => con.value)
                      .join(', ')}
                  </div>
                  <div className="mail">{item.position_name}</div>
                </div>
              </>
            ) : (
              <>{item.value || item}</>
            )}
          </li>
        ))
      ) : (
        <li className="projPopupItem persons">Нет совпадений</li>
      )}
    </ul>
  )
}
