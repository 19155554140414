import FileDownloader from '@/features/effective-contract/excel/ui/FileDownloader';
import { IconChartBar } from '@tabler/icons';
import React, { useState } from 'react';
import cls from './ExportButton.module.less';

export const ExportAppeals = () => {
  const [showFileDownloader, setShowFileDownloader] = useState(false);

  const handleButtonClick = () => {
    setShowFileDownloader(true);
  };

  return (
    <>  
      {showFileDownloader && <FileDownloader />}
      <div className={cls.showRate} onClick={handleButtonClick}>
        <span className={cls.text}>Выгрузить апелляции</span>
        <IconChartBar color="#7671DD" size={25} />
      </div>
    </>
  );
};
