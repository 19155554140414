/* eslint-disable no-console */
import { useState, useMemo, useEffect, } from 'react'
import './ProjectSchedule.less'
import StepCard from './StepCard'
import { useSelector, } from 'react-redux'
import { IconCirclePlus, IconCalendarMinus, } from '@tabler/icons'
import TaskPopup from './TaskPopup/TaskPopup'
import Calendar from 'react-calendar'
import { format, } from 'date-fns'
import { useAddStage, useStagesList, } from '../../../../gql/hooks/useProjects'
import _ from 'lodash'
import clsx from 'clsx'
import ru from 'date-fns/locale/ru'
import { Preloader, } from '@/shared'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'


function ProjectSchedule ({
  project,
}: any) {
  const role = useGetRole()

  const [
    isTaskPopup,
    setIsTaskPopup,
  ] = useState(false)
  const [
    date,
    setDate,
  ] = useState<any>(null)
  const [
    calendar,
    setCalendar,
  ] = useState(false)
  const [
    title,
    setTitle,
  ] = useState<any>(null)
  const [
    stageId,
    setStageId,
  ] = useState(null)

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const newStage = useSelector(state => state.subscriptions.stageChanged)

  const [
    paChangeStage,
  ] = useAddStage()


  function addTask (i: any) {
    setIsTaskPopup(true)
    setStageId(i)
  }

  const params = {
    req_id: project.req_id,
  }
  const { refetch, data, error, loading, }: { refetch: any, data: any, error?: any, loading: boolean } = useStagesList(params)

  useEffect(() => {
    refetch()
  }, [
    refetch,
    newStage,
  ])

  const stages = useMemo(() => {
    if (error) {
      return <div>.....error....</div>
    } else if (loading) {
      return <Preloader />
    }
    return (
      <ul style={ {
        display: 'flex',
        flexWrap: 'wrap',
      } }>{
          data.paStages.map((i: any) => <li key={ i.stage_num }><StepCard addTask={ addTask } i={ i } /></li>)
        }
      </ul>
    )
  }, [
    data,
    error,
    loading,
  ])


  function handleAddStage () {
    const params = {
      req_id: project.req_id,
      event: 'stageChanged',
      // @ts-ignore: Unreachable code error
      stage_num: data?.paStages.length > 0 ? _.last(data?.paStages).stage_num + 1 : 1,
      data: {
        date: format(new Date(date), 'yyyy-MM-dd'),
        title,
      },
    }

    paChangeStage({ variables: { params, }, })
      .then(res => console.log('стадия создана', res))
      .catch(e => console.log('stage err', e))
  }


  return (
    <div className="projSchedule">
      <h2>Этапность проекта</h2>
      { role !== 'student' && <div className="addStep">
        <input type="text" className="addInput" placeholder='Введите этап проекта' onChange={ e => setTitle(e.target.value) } />
        <div className="dateWrap"><div onClick={ () => setCalendar(!calendar) }><input type="text" placeholder='Срок исполнения' onClick={ () => setCalendar(!calendar) } disabled value={ date ? format(new Date(date), 'dd MMM yy', { locale: ru, }) : ''/* rangeDate ? `С ${format(new Date(rangeDate[0]), "yyyy.MM.dd")} по ${format(new Date(rangeDate[1]), "yyyy.MM.dd")}` : '' */ } /></div>
          <IconCalendarMinus color="#7F8191" onClick={ () => setCalendar(!calendar) } />
          { calendar ? <Calendar
            formatMonthYear={ (locale: any, date: any) => format(date, 'dd MMMM yyyy', { locale: ru, }).slice(0, 3) + format(date, 'dd MMMM yyyy', { locale: ru, })[3].toUpperCase() + format(date, 'dd MMMM yyyy', { locale: ru, }).slice(4) }
            locale={ 'Ru' }
            className={ calendar ? 'ScheduleCalendar ' : 'ScheduleCalendar off' }
            onChange={ setDate }
            selectRange={ false }
          /> : null } 
        </div>
        <button className="statusButton buttonActive" onClick={ handleAddStage } disabled={ (!date || !title) }>Добавить этап<IconCirclePlus color="white" /></button>
      </div> }
      { stages }
      <TaskPopup isOpen={ isTaskPopup } handlePopup={ () => setIsTaskPopup(!isTaskPopup) } id={ stageId } project={ project } />
      <div className={ clsx('hiddenLayer', calendar && 'hiddenLayer_vis') } onClick={ () => setCalendar(!calendar) } />
    </div>
  )
}

export default ProjectSchedule
