import { gql, } from '@/__generated__/gql'
import { useQuery, useMutation, useSubscription, gql as gqlApollo, } from '@apollo/client'

const GET_EDUCATION_PLAN = gqlApollo(`
  query EducationPlan($i:EducationPlanInput) {
    educationPlan(input:$i){
      user_id
      recordbook
      plans {
        ep_id
        ep_number
        individual_plan
        admission_start
        admission_end
        date
    }
   }
  }
`)

const GET_DISCIPLINES = gql(`
query EPDisciplines($i:EducationPlanDisciplinesInput) {
  educationPlanDisciplines(input:$i)
}
`)

const GET_ACTIVE_STATS = gql(`
query States($type: DisciplineType) {
  esDisciplinesState(type: $type)
}
`)

const GET_TRACKS_ACTIVE_STAT = gql(`
query TrackState {
  esTrackState
}
`)

const GET_DISCIPLINES_MOODLE = gql(`
query DisciplinesBySemester($i:DisciplinesBySemestersInput!) {
  bySemester: esDisciplinesBySemesters(input:$i)
  common: esDisciplinesBySemesters(input:{
    filter:{
      semester:-1
    }
  })
 }
`)

const GET_MOODLE_LINKS = gqlApollo(`
  query MoodleCourses($i:MoodleCoursesInput) {
    moodleCourses(input:$i)
  }
`)

const GET_ONE_MOODLE = gqlApollo(`
  query GetOne($name: String!, $semester: [Int] ) {
    esMoodleCoursesByDiscipline(discipline_name:$name, semester:$semester)
  }
`)

const GET_FACULTATIVES = gqlApollo(`
  query ListFacultatives($i:FacultativeInput!){
    esFacultative(input:$i)
  }
`)

const GET_PARTICIPANTS_FAC = gqlApollo(`
  query Signed($i:SignedStudentsInput!){
    esSignedStudents(input:$i)
  }
`)

const GET_PROCEDURE_INFO = gqlApollo(`
  query GetDisciplineProcedure($i:DisciplineType!){
    esDisciplineProcedure(type: $i){
      procedure_info
      procedure_data
    }
  }
`)

const GET_STATS = gqlApollo(`
  query GetStats($i:DisciplineStatsInput) {
    esDisciplineStats(input: $i)
  }
`)

const GET_TRACKS = gqlApollo(`
query GetTracks($i:TrackListInput!) {
  esTrackList(input: $i){
    items {
    is_active
    is_visible
    max_participants
    track_id
    is_signed
    name
    sign_count
    file
    data
    disciplines{
      discipline_id
      name
    }
    }
  }
}
`)


const GET_TRACKS_DISCIPLINE = gqlApollo(`
  query GetTrackDisciplines($i:TrackDisciplinesInput){
    esTrackDisciplines(input:$i){
      name
      semester
      discipline
      info
      discipline_id
      semester
      hours
      teachers
      details
      themes
      discipline
    }
  }
`)

const GET_TRACK_STUDENTS = gqlApollo(`
  query GetTrackStudents($i: TrackStudentsInput!) {
    esTrackStudents(input: $i){
      full_name
      disciplines
      student_info
      student_id
    }
  }
`)

const GET_SIGN_STATUS = gqlApollo(`
  query GetSignStatus($id: UUID!){
    esGetSignStatus(id: $id)
  }
`)

const GET_PROJECT_THEMES = gqlApollo(`
  query GetProjectThemes($i: ProjectThemesInput){
    paProjectThemes(input: $i)
  }
`)


const CREATE_FACULTATIVE = gqlApollo(`
  mutation CreateFacultative ($i:CreateFacultativeInput!){
    esCreateFacultative(input:$i){
      facultative_id
      data
      is_active
      is_visible
    }
  }
`)

const CREATE_TRACK = gqlApollo(`
  mutation CreateTrack ($i:CreateTrackInput!){
    esTrackCreate(input:$i)
  }
`)

const UPDATE_TRACK = gqlApollo(`
  mutation UpdateTrack ($i:TrackUpdateInput!){
    esTrackUpdate(input:$i)
  }
`)

const SIGN_UP_TRACK = gqlApollo(`
  mutation SignUpTrackNew ($i:SignUpTrackInput!){
    esSignUpTrackNew(input: $i)
  }
`)


const SIGN_OUT_TRACK = gqlApollo(`
  mutation SignOutTrack ($i:SignOutTrackInput!){
    esSignOutTrack(input: $i)
  }
`)

const SIGN_OUT_ONE_TRACK = gql(`
mutation SignOutOneTrack ($i: SignOutTrackStudentInput!){
  esSignOutTrackStudent(input: $i)
}
`)

const UPDATE_TRACK_DISCIPLINE = gql(`
mutation UpdateTrackDiscipline($i:TrackDisciplineUpdateInput!){
  esTrackUpdateDiscipline(input:$i)
}
`)

const SIGN_UP_FACULTATIVE = gql(`
mutation SignUp($i:SignUpFacultativeInput!){
  esSignUpFacultative(input:$i)
}
`)

const SIGN_OUT_FACULTATIVE = gql(`
mutation SignOut($i:SignOutFacultativeInput!){
  esSignOutFacultative(input:$i)
}
`)

const SIGN_UP_PROJECT_THEME = gql(`
 mutation SignUpProjectTheme($i:ProjectThemeSignUpInput!){
  paProjectThemeSignUp(input:$i)
 }
`)

const SIGN_OUT_PROJECT_THEME = gql(`
mutation SignOutProjectTheme($i: ProjectThemeSignOutInput!){
  paProjectThemeSignOut(input:$i)
}
`)

const DELETE_FACULTATIVE = gql(`
mutation DeleteFacultative($i: DeleteFacultativeInput!) {
  esDeleteFacultative(input:$i){
    facultative_id
  }
}
`)

const DELETE_TRACK = gql(`
mutation DeleteTrack($track_id:[Int!]!) {
  esTrackDelete(track_id: $track_id)
}
`)


const CREATE_PROJECT_THEME = gql(`
mutation CreateProjectTheme($i:ProjectThemeCreateInput!){
  paProjectThemeCreate(input: $i)
}
`)

const CHANGE_ACTIVE_STATUS = gql(`
mutation esDisciplineActivate($is_active: Boolean!, $type: DisciplineType) {
  esDisciplineActivate(is_active: $is_active, type: $type)
}
`)

const CHANGE_TRACKS_ACTIVE = gql(`
mutation ChangeActiveTrack($is_active: Boolean!){
  esTrackActivate(is_active: $is_active)
}
`)

const CHANGE_TRACKS_ACTIVE_ONE = gql(`
mutation ChangeActiveTrackOne($is_active: Boolean!, $track_id: Int){
  esTrackActivate(is_active: $is_active, track_id: $track_id)
}
`)

const UPDATE_TRACK_VISIBILITY = gql(`
mutation UpdateTrackVisibility($is_visible: Boolean!, $track_id: Int!) {
  esTrackUpdateVisibility(is_visible: $is_visible, track_id: $track_id)
}
`)

const CHANGE_ONE_ACTIVE_STATUS = gql(`
mutation esDisciplineActivateOne($is_active: Boolean!, $facultative_id: [Int!], $type:DisciplineType) {
  esDisciplineActivate(is_active: $is_active, facultative_id: $facultative_id, type: $type)
}
`)

const CHANGE_PROCEDURE_INFO = gql(`
mutation EditProcedure($i:EditDisciplineProcedureInput!){
  esEditDisciplineProcedure(input:$i){
    procedure_info
    procedure_data
  }
}
`)

const UPDATE_STATS = gql(`
mutation UpdateStats($i:UpdateStatsInput!){
  esUpdateDisciplineStats(input: $i)
}
`)

const SEND_DATA = gql(`
mutation Send($i:[DisciplineType]!) {
  esESB_SelectedDiscipline(mode: $i)
}
`)


const FACULTATIVE_CHANGED = gql(`
subscription OnFacultativeChanged {
  facultativesChanged
}
`)

const TRACKS_CHANGED = gql(`
subscription OnTracksChanged {
  esTracksChanged
}
`)

const PROJECT_THEMES_CHANGED = gql(`
subscription OnProjectThemeChange {
  projectIntroChanged
}
`)


export const useGetEducationPlan = (i: any) => useQuery(GET_EDUCATION_PLAN, { variables: { i, }, })
export const useGetDisciplines = (i: any) => useQuery(GET_DISCIPLINES, { variables: { i, }, })
export const useGetMoodleLinks = (i: any) => useQuery(GET_MOODLE_LINKS, { variables: { i, }, })
export const useGetFacultatives = (i: any) => useQuery(GET_FACULTATIVES, { variables: { i, }, })
export const useGetTracks = (i: any) => useQuery(GET_TRACKS, { variables: { i, }, })
export const useGetProjectThemes = (i: any) => useQuery(GET_PROJECT_THEMES, { variables: { i, }, })
export const useGetTrackDisciplines = (i: any) => useQuery(GET_TRACKS_DISCIPLINE, { variables: { i, }, })
export const useGetParticipantsFac = (i: any) => useQuery(GET_PARTICIPANTS_FAC, { variables: { i, }, })
export const useGetTrackStudents = (i: any) => useQuery(GET_TRACK_STUDENTS, { variables: { i, }, })
export const useGetProcedureInfo = (i: any) => useQuery(GET_PROCEDURE_INFO, { variables: { i, }, })
export const useGetStats = (i: any) => useQuery(GET_STATS, { variables: { i, }, })
export const useGetOneMoodle = (name: any, semester: any) => useQuery(GET_ONE_MOODLE, {
  variables: {
    name,
    semester,
  },
})
export const useGetMoodleCourses = (i: any) => useQuery(GET_DISCIPLINES_MOODLE, { variables: { i, }, })
export const useGetActiveStats = (type?: any) => useQuery(GET_ACTIVE_STATS, { variables: { type, }, })
export const useGetTrackActiveStats = () => useQuery(GET_TRACKS_ACTIVE_STAT)
export const useGetSignStatus = (id: any) => useQuery(GET_SIGN_STATUS, { variables: id, })


export const useCreateFacultative = (i: any) => useMutation(CREATE_FACULTATIVE, { variables: { i, }, })
export const useCreateTrack = () => useMutation(CREATE_TRACK)
export const useCreateProjectTheme = () => useMutation(CREATE_PROJECT_THEME)
export const useUpdateTrack = (i: any) => useMutation(UPDATE_TRACK, { variables: { i, }, })
export const useUpdateTrackDiscipline = (i: any) => useMutation(UPDATE_TRACK_DISCIPLINE, { variables: { i, }, })
export const useSignUpFacultative = (i: any) => useMutation(SIGN_UP_FACULTATIVE, { variables: { i, }, })
export const useSignUpTrack = () => useMutation(SIGN_UP_TRACK)
export const useSignOutTrack = () => useMutation(SIGN_OUT_TRACK)
export const useSignOutOneTrack = () => useMutation(SIGN_OUT_ONE_TRACK)
export const useSignOutFacultative = (i: any) => useMutation(SIGN_OUT_FACULTATIVE, { variables: { i, }, })
export const useSignOutProjectTheme = () => useMutation(SIGN_OUT_PROJECT_THEME)
export const useDeleteFacultative = (i: any) => useMutation(DELETE_FACULTATIVE, { variables: { i, }, })
export const useDeleteTrack = (track_id: any) => useMutation(DELETE_TRACK, { variables: { track_id, }, })
export const useUpdateProcedure = (i: any) => useMutation(CHANGE_PROCEDURE_INFO, { variables: { i, }, })
export const useUpdateStats = (i: any) => useMutation(UPDATE_STATS, { variables: { i, }, })
export const useUpdateActiveStatus = (is_active: any, type?: any) => useMutation(CHANGE_ACTIVE_STATUS, {
  variables: {
    is_active,
    type,
  },
})
export const useUpdateTracksActive = (is_active: any) => useMutation(CHANGE_TRACKS_ACTIVE, { variables: { is_active, }, })
export const useUpdateTrackActiveOne = (is_active?: any, track_id?: any) => useMutation(CHANGE_TRACKS_ACTIVE_ONE, {
  variables: {
    is_active,
    track_id,
  },
})
export const useUpdateTrackVisibility = (is_visible?: any, track_id?: any) => useMutation(UPDATE_TRACK_VISIBILITY, {
  variables: {
    is_visible,
    track_id,
  },
})
export const useUpdateOneActiveStatus = () => useMutation(CHANGE_ONE_ACTIVE_STATUS)
export const useSendData = (i: any) => useMutation(SEND_DATA, { variables: { i, }, })
export const useSignUpProjectTheme = () => useMutation(SIGN_UP_PROJECT_THEME)

export const useFacultativeChanged = () => useSubscription(FACULTATIVE_CHANGED)
export const useTracksChanged = () => useSubscription(TRACKS_CHANGED)
export const useProjectThemeChanged = () => useSubscription(PROJECT_THEMES_CHANGED)
