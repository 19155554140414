import { useME, } from '@/shared/api'
import { UserRoles, } from '@/shared/constants/userRoles'
import { useEffect, } from 'react'
interface JivoWidgetProps {
  role: string;
}

export const JivoWidget: React.FC<JivoWidgetProps> = ({ role, }) => {
  const me: any = useME()

  const contacts = me?.data?.me?.data?.contacts || []

  const phoneContacts: any = Array.isArray(contacts)
    ? contacts.filter((contact: any) => contact?.type_contact_information === 'Телефон')
      .map((contact: any) => ({
        kind_contact_information: contact?.kind_contact_information,
        represent: contact.represent,
      }))
    : []

  const phoneNumber = phoneContacts.length > 0 ? phoneContacts[0].represent : '+79000000000'

  const mailContacts: any = Array.isArray(contacts)
    ? contacts.filter((contact: any) => contact?.type_contact_information === 'АдресЭлектроннойПочты')
      .map((contact: any) => ({
        kind_contact_information: contact?.kind_contact_information,
        represent: contact.represent,
      }))
    : []

  const mail = mailContacts.length > 0 ? mailContacts[0].represent : 'email@example.com'

  const injectedJavaScript = `
    function jivo_onLoadCallback() {
      jivo_api.setContactInfo({
        name: "${me?.data?.me?.data?.full_name || 'Guest'}",
        email: "${mail || 'email@example.com'}",
        phone: "${phoneNumber}",
        description: "User profile information"
      });
    }
  `

  useEffect(() => {
    const scriptSrcMap: { [key: string]: string } = {
      [UserRoles.STUDENT]: '//code.jivo.ru/widget/Nk638lp4va',
      [UserRoles.STUDENT_RAKUS]: '//code.jivo.ru/widget/Nk638lp4va',
      [UserRoles.WORKER]: '//code.jivo.ru/widget/Nk638lp4va', //teacher
      [UserRoles.TEACHER_GPH]: '//code.jivo.ru/widget/Nk638lp4va',
      [UserRoles.EMPLOYEE]: '//code.jivo.ru/widget/Nk638lp4va',
    }

    const scriptSrc = scriptSrcMap[role]

    if (scriptSrc) {
      const script = document.createElement('script')
      script.src = scriptSrc
      script.async = true
      document.body.appendChild(script)

      const scriptElement = document.createElement('script')
      scriptElement.text = injectedJavaScript
      document.body.appendChild(scriptElement)

      return () => {
        document.body.removeChild(script)
        document.body.removeChild(scriptElement)
      }
    }
  }, [
    role,
    injectedJavaScript,
  ])

  const openJivoChat = () => {
    window.location.href = 'https://jivo.chat/OMAS4HokqF';
  };

  return (
    <>
      {/* {(role === UserRoles.WORKER || role === UserRoles.TEACHER_GPH) &&  <Button className={cls.button} variant='filled' onClick={() => openJivoChat()} disabled={false}>
        <Icon Svg={TechSupportRobotSvg} className="custom-icon-class" width={28} height={24} />
        <Text size='s' bold={false} text={'Поддержка'} className={cls.editText}/>
      </Button>} */}
    </>
  )
}
