/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { UserRoles } from '@/shared/constants/userRoles'
import {
  MAIN_ROUTE,
  MyReferences_Route,
  NEWS_ROUTE,
  SCOLARSHIP,
  PAYMENT,
  EFFECTIVE_CONTRACT_ROUTE,
  EFFECTIVE_CONTRACT_AMS_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENTS_AMS_ROUTE,
  EFFECTIVE_CONTRACT_APPEALS_ROUTE,
  EFFECTIVE_CONTRACT_STEPS_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE,
  EFFECTIVE_CONTRACT_APPEALS_AMS_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_THRESHOLD_VALUES_ROUTE,
  PORTFOLIO_TEMPLATES,
  PORTFOLIO_RATING,
  PORTFOLIO,
  MODERATE_PORTFOLIO,
  MODERATE_NOTIFICATIONS,
  PROJECT_SEMINAR,
} from './routes'

export const URL = process.env.REACT_APP_URL
export const dev = 'lk-dev.tsutmb.ru'
export const isDev = URL === dev

export const navItems = [
  {
    value: 'Главная',
    role: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
      UserRoles.DMS_MODER,
      UserRoles.UFPN_MODER,
      UserRoles.OSRV_MODER,
      UserRoles.TESTER,
      UserRoles.PA_MODER,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.URVO_ADMIN,
      UserRoles.AUP_ADMIN,
      UserRoles.DIRECTOR_INSTITUTE,
      UserRoles.DIRECTOR_FACULTY,
      UserRoles.AUP_PFU_MODER,
      UserRoles.AUP_PK_MODER,
      UserRoles.AUP_DMS_MODER,
      UserRoles.AUP_CK_MODER,
      UserRoles.SELFSIGNUP,
      UserRoles.EMPLOYEE,
      UserRoles.PORTFOLIO_MFC_MODER,
      UserRoles.PORTFOLIO_KURATOR,
      UserRoles.UNKNOWN,
    ],
    path: MAIN_ROUTE,
    icon: 'home',
  },
  {
    value: 'Учебный процесс',
    role: [
      UserRoles.STUDENT,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
      UserRoles.STUDENT_RAKUS,
      UserRoles.PA_MODER,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.TESTER,
      UserRoles.OSRV_MODER,
//      UserRoles.EMPLOYEE,
    ],
    icon: 'notebook',
    items: [
      {
        value: "Расписание",
        icon: "calendar-event",
        path: "/schedule-teacher",
        role: [
          UserRoles.WORKER,
          UserRoles.TEACHER_GPH,
          UserRoles.TESTER
        ],
      },
      {
        value: 'Мое обучение',
        depth: 2,
        role: [
          UserRoles.STUDENT,
          UserRoles.STUDENT_RAKUS,
          UserRoles.OSRV_MODER,
          UserRoles.PROSMOTR_MOE_OBUCHENIE,
          UserRoles.TESTER,
        ],
        icon: 'contacts-book',
        items: [
          {
            value: 'Высшее образование',
            path: '/high-education',
            role: [
              UserRoles.STUDENT,
              UserRoles.STUDENT_RAKUS,
              UserRoles.OSRV_MODER,
              UserRoles.PROSMOTR_MOE_OBUCHENIE,
            ],
            icon: 'journal',
          },
          {
            value: 'ДПО',
            path: '/dpo-education',
            role: [],
            icon: 'journal',
          },
          {
            value: 'Мэйджоры',
            path: '/specialties-list',
            role: [
              UserRoles.TESTER,
            ],
            icon: 'journal',
            environment: dev,
          },
        ],
      },
      {
        value: 'Образовательные сервисы',
        depth: 2,
        role: [
          UserRoles.STUDENT,
          UserRoles.STUDENT_RAKUS,
          UserRoles.PA_MODER,
          UserRoles.WORKER,
          UserRoles.TEACHER_GPH,
          UserRoles.TESTER,
          UserRoles.OSRV_MODER,
          UserRoles.OSRV_MODER,
//          UserRoles.EMPLOYEE,
        ],
        icon: 'contacts-book',
        items: [
          {
            value: 'Расписание',
            icon: 'calendar-event',
            path: '/schedule',
            role: [
              UserRoles.STUDENT,
              UserRoles.STUDENT_RAKUS,
            ],
          },
          {
            value: 'Зачетная книжка',
            icon: 'book',
            path: '/record-book',
            role: [
              UserRoles.STUDENT,
              UserRoles.STUDENT_RAKUS,
            ],
          },
          {
            value: 'Электронный журнал',
            path: '/study-journal',
            role: [],
            icon: 'journal',
          },
          {
            value: 'Проектная деятельность',
            depth: 2,
            role: [
              UserRoles.STUDENT,
              UserRoles.STUDENT_RAKUS,
              UserRoles.WORKER,
              UserRoles.TEACHER_GPH,
              UserRoles.PA_MODER,
              UserRoles.TESTER,
            ],
            icon: 'briefcase',
            items: [
              {
                value: 'Биржа проектов',
                path: '/projects-all',
                role: [
                  UserRoles.STUDENT,
                  UserRoles.STUDENT_RAKUS,
                  UserRoles.WORKER,
                  UserRoles.TEACHER_GPH,
                  UserRoles.PA_MODER,
                  UserRoles.TESTER,
                ],
                icon: 'projects-all',
              },
              {
                value: 'Мои проекты',
                path: '/my-projects',
                role: [
                  UserRoles.STUDENT,
                  UserRoles.STUDENT_RAKUS,
                  UserRoles.WORKER,
                  UserRoles.TEACHER_GPH,
                  UserRoles.TESTER,
                  UserRoles.EMPLOYEE,
                ],
                icon: 'my-projects',
              },
              {
                value: 'Мои заявки',
                path: '/my-applications',
                role: [
                  UserRoles.WORKER,
                  UserRoles.TEACHER_GPH,
                  UserRoles.TESTER,
                  UserRoles.EMPLOYEE,
                ],
                icon: 'application-process',
              },
              {
                value: 'Проектный семинар',
                path: PROJECT_SEMINAR,
                role: [
                  UserRoles.WORKER,
                  UserRoles.TEACHER_GPH,
                ],
                icon: 'projects-all',
              },
              /*  {
               value: "Модерация проектов",
               path: "/new-project",
               role: [UserRoles.PA_MODER],
               icon: "new-project"
             }, */
              {
                value: 'Модерация заявок',
                path: '/moderate-applications',
                role: [
                  UserRoles.PA_MODER,
                  UserRoles.TESTER,
                ],
                icon: 'new-project',
              },
            ],
          },
        ],
      },
      /* {
        value: "Библиотека",
        path: "/library",
        role: [UserRoles.STUDENT, UserRoles.STUDENT_RAKUS, UserRoles.TESTER],
        icon: "notebook"
      }, */
      // {
      //   value: "ОПВО",
      //   path: "/opvo",
      //   icon: "archive",
      //   role: [UserRoles.STUDENT, UserRoles.STUDENT_RAKUS, /* UserRoles.PA_MODER */]
      // },
      {
        value: 'Курсовые и дипломные',
        path: '/coursework',
        role: [
          // UserRoles.STUDENT,
          // UserRoles.STUDENT_RAKUS,
          // UserRoles.OSRV_MODER,
          // UserRoles.TESTER,
        ],
        icon: 'file-analytics',
      },
    ],
  },
  {
    value: 'МФЦ',
    role: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
//      UserRoles.WORKER,
//      UserRoles.EMPLOYEE,
    ],
    icon: 'license',
    items: [
/*      {
        value: 'Мои заявления',
        path: MFC_MY_APPLICATIONS_ROUTE,
        icon: 'ref',
        role: [
          UserRoles.STUDENT,
          UserRoles.STUDENT_RAKUS,
          UserRoles.WORKER,
          UserRoles.EMPLOYEE,
        ],
        environment: dev,
      },*/
      {
        value: 'Мои справки',
        path: MyReferences_Route,
        icon: 'ref',
        role: [
          UserRoles.STUDENT,
          UserRoles.STUDENT_RAKUS,
        ],
      },
/*      {
        value: 'Служебные записки',
        path: MY_DUTY_NOTES,
        icon: 'ref',
        role: [
          UserRoles.WORKER,
          UserRoles.EMPLOYEE,
        ],
        environment: dev,
      },
      {
        value: 'История активности',
        path: MFC_LOGS,
        icon: 'ref',
        role: [
          UserRoles.WORKER,
          UserRoles.EMPLOYEE,
        ],
        environment: dev,
      },*/
    ],
  },
{
    value: 'Портфолио',
    icon: 'briefcase',
    role: [
      UserRoles.URTOS_MODER,
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.PORTFOLIO_MFC_MODER,
      UserRoles.PORTFOLIO_KURATOR,
    ],
//    environment: dev,
    items: [
      {
        value: 'Портфолио',
        path: PORTFOLIO,
        icon: 'portfolioCase',
        role: [
          UserRoles.STUDENT,
          UserRoles.STUDENT_RAKUS,
        ],
      },
      {
        value: 'Модерация портфолио',
        path: MODERATE_PORTFOLIO,
        icon: 'portfolioCase',
        role: [
          UserRoles.URTOS_MODER,
          UserRoles.PORTFOLIO_MFC_MODER,
          UserRoles.PORTFOLIO_KURATOR,
        ],
      },
      {
        value: 'Рейтинг обучающихся',
        path: PORTFOLIO_RATING,
        icon: 'personStar',
        role: [
          UserRoles.STUDENT,
          UserRoles.STUDENT_RAKUS,
          UserRoles.URTOS_MODER,
          UserRoles.PORTFOLIO_KURATOR,
        ],
      },
      {
        value: 'Шаблоны показателей',
        path: PORTFOLIO_TEMPLATES,
        icon: 'list',
        role: [
          UserRoles.URTOS_MODER,
        ],
      },
    ],
  },
  {
    value: 'Финансы',
    role: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
    ],
    icon: 'report-money',
    items: [
      {
        value: 'Оплата и задолженность',
        path: PAYMENT,
        icon: 'credit',
        role: [
          UserRoles.STUDENT,
        ],
      },
      {
        value: 'Стипендии и выплаты',
        path: SCOLARSHIP,
        icon: 'wallet',
        role: [
          UserRoles.STUDENT,
          UserRoles.STUDENT_RAKUS,
        ],
      },
    ],
  },
  {
    value: 'Биржа труда',
    role: [
      UserRoles.TESTER,
    ],
    icon: 'search',
    items: [],
  },

  {
    value: 'Эффективный контракт',
    role: [
      UserRoles.WORKER,
//      UserRoles.EMPLOYEE,
      UserRoles.TESTER,
      UserRoles.DMS_MODER,
      UserRoles.UFPN_MODER,
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URVO_MODER,
      UserRoles.URVO_TWO_MODER,
      UserRoles.URVO_ADMIN,
      UserRoles.AUP_ADMIN,
      UserRoles.DIRECTOR_INSTITUTE,
      UserRoles.DIRECTOR_FACULTY,
      UserRoles.AUP_PFU_MODER,
      UserRoles.AUP_PK_MODER,
      UserRoles.AUP_DMS_MODER,
      UserRoles.AUP_CK_MODER,
    ],
    icon: 'tablet',
    items: [
      {
        value: 'Эффективный контракт',
        path: EFFECTIVE_CONTRACT_ROUTE,
        icon: 'tablet',
        role: [
          UserRoles.WORKER,
//          UserRoles.EMPLOYEE,
          UserRoles.TESTER,
        ],
      },
      {
        value: 'Эффективный контракт АУП',
        path: EFFECTIVE_CONTRACT_AMS_ROUTE,
        icon: 'tablet-fill',
        role: [
          UserRoles.DIRECTOR_INSTITUTE,
          UserRoles.DIRECTOR_FACULTY,
        ],
      },
      // {
      //   value: "Рейтинг преподавателей",
      //   path: TEACHER_RATING_ROUTE,
      //   icon: "personStar",
      //   role: [UserRoles.WORKER, UserRoles.EMPLOYEE, UserRoles.TESTER],
      // },
      {
        value: 'Модерация достижений',
        path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE,
        icon: 'tablet',
        role: [
          UserRoles.TESTER,
          UserRoles.DMS_MODER,
          UserRoles.UFPN_MODER,
          UserRoles.UONID_MODER,
          UserRoles.DM_MODER,
          UserRoles.UKP_MODER,
          UserRoles.TPD_MODER,
          UserRoles.URVO_MODER,
          UserRoles.URVO_TWO_MODER,
          UserRoles.URVO_ADMIN,
        ],
      },
      {
        value: 'Модерация достижений АУП',
        path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENTS_AMS_ROUTE,
        icon: 'tablet-fill',
        role: [
          UserRoles.AUP_ADMIN,
          UserRoles.AUP_PFU_MODER,
          UserRoles.AUP_PK_MODER,
          UserRoles.AUP_DMS_MODER,
          UserRoles.AUP_CK_MODER,
        ],
      },
      {
        value: 'Модерация апелляций АУП',
        path: EFFECTIVE_CONTRACT_APPEALS_AMS_ROUTE,
        icon: 'folderGearBlack',
        role: [
          UserRoles.AUP_ADMIN,
        ],
      },
      {
        value: 'Модерация апелляций',
        path: EFFECTIVE_CONTRACT_APPEALS_ROUTE,
        icon: 'folderGear',
        role: [
          UserRoles.TESTER /* UserRoles.UONID_MODER, UserRoles.DM_MODER, UserRoles.UKP_MODER, UserRoles.TPD_MODER, UserRoles.URVO_MODER, */,
          UserRoles.URVO_ADMIN,
        ],
      },
      {
        value: 'Этапы эффективного контракта',
        path: EFFECTIVE_CONTRACT_STEPS_ROUTE,
        icon: 'path',
        role: [
          UserRoles.URVO_ADMIN,
        ],
      },
      {
        value: 'Пороговые значения',
        path: EFFECTIVE_CONTRACT_MODERATE_THRESHOLD_VALUES_ROUTE,
        icon: 'threshold',
        role: [
          UserRoles.AUP_ADMIN,
        ],
      },
    ],
  },

  {
    value: 'Информация',
    role: [
      UserRoles.STUDENT,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
      UserRoles.STUDENT_RAKUS,
      UserRoles.OSRV_MODER,
      UserRoles.PA_MODER,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.EMPLOYEE,
      UserRoles.TESTER,
      UserRoles.SELFSIGNUP,
      UserRoles.PORTFOLIO_MFC_MODER,
      UserRoles.PORTFOLIO_KURATOR,
    ],
    icon: 'info-circle',
    items: [
      {
        value: 'Новости',
        path: NEWS_ROUTE,
        icon: 'news',
        role: [
          UserRoles.STUDENT,
          UserRoles.PROSMOTR_MOE_OBUCHENIE,
          UserRoles.STUDENT_RAKUS,
          UserRoles.OSRV_MODER,
          UserRoles.PA_MODER,
          UserRoles.WORKER,
          UserRoles.TEACHER_GPH,
          UserRoles.UONID_MODER,
          UserRoles.DM_MODER,
          UserRoles.UKP_MODER,
          UserRoles.TPD_MODER,
          UserRoles.URTOS_MODER,
          UserRoles.URVO_MODER,
          UserRoles.EMPLOYEE,
          UserRoles.TESTER,
          UserRoles.SELFSIGNUP,
          UserRoles.PORTFOLIO_MFC_MODER,
          UserRoles.PORTFOLIO_KURATOR,
        ],
      },
      {
        value: 'Объявления',
        path: '/anounces',
        icon: 'anounces',
        role: [
          UserRoles.STUDENT,
          UserRoles.PROSMOTR_MOE_OBUCHENIE,
          UserRoles.STUDENT_RAKUS,
          UserRoles.OSRV_MODER,
          UserRoles.PA_MODER,
          UserRoles.WORKER,
          UserRoles.TEACHER_GPH,
          UserRoles.UONID_MODER,
          UserRoles.DM_MODER,
          UserRoles.UKP_MODER,
          UserRoles.TPD_MODER,
          UserRoles.URTOS_MODER,
          UserRoles.URVO_MODER,
          UserRoles.EMPLOYEE,
          UserRoles.TESTER,
          UserRoles.SELFSIGNUP,
          UserRoles.PORTFOLIO_MFC_MODER,
          UserRoles.PORTFOLIO_KURATOR,
        ],
      },
    ],
  },

  {
    value: 'Уведомления',
    role: [
      UserRoles.NOTIFICATION_MODER,
      UserRoles.PORTFOLIO_MFC_MODER,
      UserRoles.PORTFOLIO_KURATOR,
    ],
    icon: 'bell',
    items: [
      {
        value: 'Добавление уведомлений',
        path: MODERATE_NOTIFICATIONS,
        icon: 'bell',
        role: [
          UserRoles.NOTIFICATION_MODER,
        ],
      },
    ],
  },
]

export const profilePopup = [
  {
    value: 'Профиль',
    role: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
    ],
    path: '/profile',
  },
]

export const refTypes = [
  'Справка об обучении',
  'Справка для органов Соц.защиты',
  'Справка для Пенсионного Фонда',
  'Справка о проживании в общежитии',
  'Справка о невыплате пособий при рождении ребенка',
  'Справка о среднем балле',
  'Справка-вызов',
  'Архивная справка о периоде обучения',
  'Справка о периоде и месте прохождения практики',
  'Справка, подтверждающая выдачу документа об образовании',
  'Характеристика',
  'Заверенные копии лицензии и свидетельства',
  'Выписка из зачетной книжки',
]

export const authorityTypes = [
  'ИФНС',
  'Органы социальной защиты',
  'Пенсионный фонд',
  'Военный комиссариат',
  'По месту требования',
]

export const placeToGet = [
  'Забрать в МФЦ оригинал',
  'В личный кабинет скан-копия',
  'На электронную почту скан-копия',
]

export const refAmount = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
]
