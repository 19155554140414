import './UsersTable.less'

import { Table, } from 'antd'
import { Link, } from 'react-router-dom'
import { format, } from 'date-fns'
import {
  EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_BY_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_APPEAL_BY_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_BY_AMS_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_APPEAL_BY_AMS_ROUTE,
} from '@/shared/constants/routes'
import { useGetDepartmentList, } from '@/legacy/gql/hooks/common/useGetInstitutes'
import { useEffect, useState, } from 'react'
interface DepartmentList {
  id: string
  name: string
}

const TableContainer = ({
  isLoading,
  users = [],
  isAppealsTable,
  onClickSort,
  mode,
}: any) => {
  const baseAchievementUrl = mode === 'AMS'
    ? EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_BY_AMS_ROUTE
    : EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_BY_ROUTE

  const baseAppealUrl = mode === 'AMS'
    ? EFFECTIVE_CONTRACT_MODERATE_APPEAL_BY_AMS_ROUTE
    : EFFECTIVE_CONTRACT_MODERATE_APPEAL_BY_ROUTE


  const departmentList = useGetDepartmentList()

  const [
    departmentNames,
    setDepartmentNames,
  ] = useState<DepartmentList[]>([])

  useEffect(() => {
    if (!departmentList.loading && departmentList.data) {
      setDepartmentNames(departmentList.data.departmentList)
    }
  }, [
    departmentList,
  ])
  const columnsAchievement = [
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      defaultSortOrder: undefined,
      sorter: true,
    },
    {
      title: 'Количество неподтвержденных достижений',
      dataIndex: 'unConfirmCount',
      defaultSortOrder: undefined,
      sorter: true,
    },
    {
      title: 'Количество подтвержденных достижений',
      dataIndex: 'confirmCount',
      defaultSortOrder: undefined,
      sorter: true,
    },
    {
      title: 'Дата последней активности',
      dataIndex: 'date',
      defaultSortOrder: undefined,
      sorter: true,
    },
  ]

  const columnsAppeals = [
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      defaultSortOrder: undefined,
      sorter: true,
    },
    {
      title: 'Количество неподтвержденных апелляций',
      dataIndex: 'appealPending',
      defaultSortOrder: undefined,
      sorter: true,
    },
    {
      title: 'Количество подтвержденных апелляций',
      dataIndex: 'appealApproved',
      defaultSortOrder: undefined,
      sorter: true,
    },
  ]

  const dataSource = users.map((user: any, index: number) => {
    const findDepartment: DepartmentList | undefined = departmentNames?.find((department: DepartmentList) => department?.id === user.institutes)

    return {
      key: user.id + index,
      fullName: isAppealsTable
        ? (
          mode === 'AMS'
            ? (
              <Link to={ `${ baseAppealUrl }?id=${ user?.id }` } >
                { user.fullName }
              </Link>
            )
            : (
              <Link to={ `${ baseAppealUrl }?id=${ user?.id }` } >
                { user.fullName }
              </Link>
            )
        )
        : (
          mode === 'AMS'
            ? (
              <Link to={ `${ baseAchievementUrl }?id=${ user?.id }&department=${findDepartment?.name}` } >
                { user.fullName }
              </Link>
            )
            : (
              <Link to={ `${ baseAchievementUrl }?id=${ user?.id }` } >
                { user.fullName }
              </Link>
            )
        ),
      unConfirmCount: user.pending,
      confirmCount: user.approved,
      appealPending: user.appealPending,
      appealApproved: user.appealApproved,
      date: user.lastUpdate ? format(new Date(user.lastUpdate), 'dd.MM.yyyy') : false,
    }
  })
  
  return (
    <div className='effective-contract-users-table'>
      <Table
        pagination={ false }
        loading={ isLoading }
        dataSource={ dataSource }
        columns={ isAppealsTable ? columnsAppeals : columnsAchievement }
        onChange={ onClickSort }
      />
    </div>
  )
}


export default TableContainer
