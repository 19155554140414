import React, { useEffect, useState, } from 'react'
import useDownload from '@/shared/api/hooks/useDownload/useDownload'
import { useGetExportedAppeals } from '@/features/effective-contract/excel/api/useGetExportedAppeals'
import TableSpinner from '@/legacy/components/EffectiveContract/TableContainer/components/TableBody/components/TableCell/components/TableSpinner/TableSpinner'



const FileDownloader = () => {
  const { data, loading, error, } = useGetExportedAppeals()
  const [
    fileId,
    setFiledId,
  ] = useState<string | null>(null)

  const { downloadUrl, errorMessage, loading: downloadLoading, } = useDownload(fileId)
  useEffect(() => {
    if (data?.ecExportAppeals .fileId) {
      setFiledId(data.ecExportAppeals.fileId)
    }
  }, [
    data,
  ])

  useEffect(() => {
    if (downloadUrl) {
      window.location.href = downloadUrl
    }
  }, [
    downloadUrl,
  ])

  return (
    <>
      <div style={{marginTop: 10,}}>
        {(loading || downloadLoading) && <><TableSpinner />Загрузка данных...</>}
        {error && <p>Error: {error.message}</p>}
        {errorMessage && <p>Error generating download URL: {errorMessage}</p>}
      </div>
    </>
  )
}

export default FileDownloader
