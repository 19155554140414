import Field from '@/legacy/components/Projects/AllProjects/Field/Field'
import { DebounceInput, } from 'react-debounce-input'
import { Checkbox, } from '@mui/material'
import PhoneInput from 'react-phone-number-input'
import { handleWorkCheck, } from '@/legacy/components/Projects/NewProject/handlers/handlers'
import { workTypes, } from '@/legacy/components/Projects/NewProject/consts/consts'
import { useState, } from 'react'

const TypeAndNameSection = ({ setField, setCurrentField, currentField, formFields, setFile, editProject, }: any) => {
  const [
    showInput,
    setShowInput,
  ] = useState(false)
  return (
    <div>
      <h2>Тип и название проекта</h2>
      <div className="type">
        <DebounceInput
          debounceTimeout={ 500 }
          type="text"
          placeholder="Название проекта"
          onChange={(e: any) => setField('projectName', e.target.value) }
          value={ formFields.projectName }
          required
          minLength={ 1 }
        />
        <Field
          items={ [
            'Исследовательские проекты',
            'Прикладные проекты',
            'Социальные проекты',
          ] }
          title="Тип проекта"
          value={ formFields.projectType }
          handler={(e: any) => setField('projectType', e) }
          onClick={ setCurrentField }
          activeField={ currentField }
        />
        <Field
          items={ [
            'Внешний заказчик',
            // 'Технопарк Державинский',
            'Университет',
          ] }
          title="Тип заказчика"
          value={ formFields.customerType }
          handler={(e: any) => setField('customerType', e) }
          onClick={ setCurrentField }
          activeField={ currentField }
        />
        {
          ((!!formFields.customerType) && (formFields.customerType !== 'Университет') && (formFields.customerType !== 'Технопарк Державинский'))
            ? <DebounceInput
              debounceTimeout={ 500 }
              type="text"
              placeholder="ФИО куратора от организации"
              onChange={(e: any) => setField('customerName', e.target.value) }
              value={ formFields.customerName }
              required
              minLength={ 1 }
            />
            : false
        }
        {
          ((!!formFields.customerType) && (formFields.customerType !== 'Университет') && (formFields.customerType !== 'Технопарк Державинский'))
            ? <PhoneInput
              defaultCountry="RU"
              placeholder="Номер телефона куратора от организации"
              value={ formFields.customerPhone }
              onChange={(e: any) => setField('customerPhone', e) }
            />
            : false
        }
        <ul className="ChecksWrap" style={ { margin: '0', } }>
          { workTypes.map((w: any) => (
            <li key={ w } onClick={ () => handleWorkCheck(w, formFields.workType, setField) }>
              <Checkbox
                checked={ w === formFields.workType }
                onChange={ () => handleWorkCheck(w, formFields.workType, setField) }
              />
              { w }
            </li>
          )) }
        </ul>
        {(formFields.customerType !== 'Внешний заказчик' || editProject?.fileId) ? null : (
          <input type="file" onChange={e => setFile(e.target.files?.[0] || null)} />
        )}
      </div>
      {editProject?.fileId && (
        <div className='type' style={{ marginBottom: 20, }}>
          {!showInput && (<button className='statusButton buttonActive' onClick={() => setShowInput(true)}>Изменить файл</button>)}
          {showInput && (
            <input type="file" onChange={e => setFile(e.target.files?.[0] || null)} />
          )}
        </div>
      )}
    </div>
  )
}
export default TypeAndNameSection
