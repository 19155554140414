import _ from 'lodash'
import { useMemo, } from 'react'
import { useGetPersons, } from '../../../gql/hooks/useProjects'
import AddStudentItem from './AddStudentItem/AddStudentItem'


const StudentsList = ({
  student,
  setNewStudents,
  newStudents,
}: any): JSX.Element => {
  const pagination = {
    offset: 0,
    limit: 200,
  }

  const { data, error, loading, } = useGetPersons(student, 'student', pagination)


  const studentsAll = useMemo(() => {
    if (error) {
      return <div>...произошла ошибка</div>
    }
    if (loading) {
      return <div>....loading...</div>
    }

    const withoutDoubles = _.uniqBy(data.persons, 'id')

    return data?.persons.map((person: any) => (
      <AddStudentItem
        key={ person.id }
        i={ person }
        setNewStudents={ setNewStudents }
        newStudents={ newStudents }
      />
    ))
  }, [
    data,
    error,
    loading,
    newStudents,
    setNewStudents,
  ])

  return (
    <>
      { studentsAll }
    </>
  )
}

export default StudentsList
