/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState, } from 'react'
import './Header.less'
import HeaderNavCurrent from './HeaderNav/HeaderNavCurrent'
import { useSelector, useDispatch, } from 'react-redux'
import { useAvatarQuery, } from '../../../legacy/redux/utilsApi/imageApi'
import {
  IconFile,
  IconPaperclip,
  IconCircleX,
  IconAlertTriangle,
  IconChevronUp,
  IconChevronDown,
  IconSnowflakeOff,
  IconSnowflake,
} from '@tabler/icons'
import ProfilePopupCurrent from './ProfilePopup/ProfilePopupCurrent'
import Popup from '@/legacy/components/common/Popup/Popup'
// import Popup from '@legacy/components/common/Popup/Popup'
import ButtonSimple from '@/legacy/components/common/ButtonSimple/ButtonSimple'
import { useFeedback, } from '@/legacy/gql/hooks/useSystem'
import TextField from '@/legacy/components/common/Fields/TextField/TextField'
import PhoneInput from 'react-phone-number-input'
import { updateDefaultAvatar, } from '@/legacy/redux/Subscriptions/SubscriptionsSlice'
import { useDefaultAvatarChanged, } from '@/legacy/gql/hooks/useAvatars'
import MoodleIcon from '@/shared/assets/images/MoodleLogo.png'
import { Alert, Snackbar, } from '@mui/material'
import { EndPoints, } from '@/shared/constants/endpoints'
import ImportantPopupMessage from './ImportantPopupMessage/ImportantPopupMessage'
import InputCheckbox from '@/legacy/components/common/InputCheckbox/InputCheckbox'
import { setSnowAnimation, } from '@/legacy/redux/utilsSlice'
import { useAppContext, } from '@/app/providers/AppProvider'

import WorkspaceIconsList from '@/entities/WorkspaceIconsList/WorkspaceIconsList'
import Banner from '@/legacy/components/Main/MainComponents/Banner/Banner'
import NewMobileAppVersionPopupMessage from '@/widgets/Header/ui/NewMobileAppVersionPopupMessage/NewMobileAppVersionPopupMessage'


const Header = () => {
  const [
    disabled,
    setDisabled,
  ] = useState(false)
  const dispatch = useDispatch()

  const { openToast, } = useAppContext()

  const useDefaultAvatar = () => {
    const { error, loading, data, } = useDefaultAvatarChanged()

    useEffect(() => {
      if (loading || error) {
        return
      }
      if (!data?.avatarChanged) {
        return
      }
      dispatch(updateDefaultAvatar())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      loading,
      error,
      data,
    ])
  }
  useDefaultAvatar()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const userName = useSelector(state => state.auth.me)
  // const dispatch = useDispatch();
  // const isPopup = useSelector((state) => state.head.isProfilePopup);
  const refresh = useSelector(
    // @ts-expect-error TS(18046): 'state' is of type 'unknown'.
    state => state.subscriptions.updateDefaultAvatar
  )

  const isSnow = useSelector((state: any) => state.util.snowAnymation)

  const realUserGuid = useSelector(
    // @ts-expect-error TS(18046): 'state' is of type 'unknown'.
    state => state.fakeChangeUser.realUserGuid
  )
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const fakeUserName = useSelector(state => state.auth.me.data?.full_name)

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const stud = useSelector(state => state.prof.studentInfo)

  const [
    isErrorPopup,
    setIsErrorPopup,
  ] = useState(false)
  const [
    errorValue,
    setErrorValue,
  ] = useState<any>(null)
  const [
    mobile,
    setMobile,
  ] = useState<any>(null)
  const [
    subject,
    setSubject,
  ] = useState<any>(null)
  const [
    email,
    setEmail,
  ] = useState<any>(null)

  const [
    file,
    setFile,
  ] = useState<any>(false)
  const [
    uploadID,
    setUploadID,
  ] = useState<any>(null)

  const [
    uiFeedback,
  ] = useFeedback(
    subject,
    `${ errorValue } контактный номер: ${ mobile } email: ${ email }`,
    {
      upload_id: [
        uploadID,
      ],
    }
  )

  const [
    anchorEl,
    setAnchorEl,
  ] = useState(null)
  const open = Boolean(anchorEl)

  const name = useMemo(() => {
    if (userName) {
      return (
        <div onClick={ openPopup } className="userName">
          { `${ userName.data.name } ${ userName.data.surname.slice(0, 1) }.` }
        </div>
      )
    }
    return <div>без имени</div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userName,
  ])

  // @ts-expect-error TS(2339): Property 'loading' does not exist on type UseQuer... Remove this comment to see the full error message
  const { refetch, data, error, loading, } = useAvatarQuery()

  useEffect(() => {
    refetch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    refresh,
  ])

  /* const image = useMemo(() => {
    if (loading)
      return <div>Ld</div>;

    if (error)
      refetch()

    if (data) {
      return (
        <img
          className="avatar"
          src={ `data:image/png;base64,${ data }` }
          alt="avatar"
        />
      );
    }

  }, [loading, error, data, refetch]); */

  function openPopup (e: any) {
    setAnchorEl(e.currentTarget)
  }

  function closePopup (e: any) {
    setAnchorEl(null)
  }

  const isDisabled = () => {
    if (!subject) {
      return true
    } else if (!errorValue) {
      return true
    } else if (!mobile) {
      return true
    } else if (disabled) {
      return true
    }

    return false
  }

  const onSubmit = () => {
    setDisabled(true)
    const formData = new FormData()
    formData.append(`feedback-attachments/`, file[0])

    fetch(EndPoints.upload, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    })
      .then(res => {
        return res.json()
      })
      .then(
        doc => setUploadID(doc.upload_id) /* setFile([...file, doc[0]]) */
      )
      .then(() => {
        uiFeedback()
          .then(res => {
            setDisabled(false)
            setIsErrorPopup(false)
          })
          .then(() => openToast?.({ message: 'Спасибо за обратную связь, вы делаете нас лучше!', }))
          .catch(e => console.log('errorPopup', e))
      })
      .catch(e => console.log('errorPopup', e))
  }

  return (
    <>
      { realUserGuid && realUserGuid !== userName?.data?.guid && (
        <Snackbar open={ realUserGuid && realUserGuid !== userName?.data?.guid }>
          <Alert severity="info">
            Вы работаете от лица: <b>{ fakeUserName }!</b>
          </Alert>
        </Snackbar>
      ) }

      <header className="hedaerWrap">
        <div className="header">
          {
            stud
              ? <a
                className="moodleLink"
                href="https://moodle1.tsutmb.ru/my/"
                target="_blank"
                rel="noreferrer"
              >
                Перейти в moodle <img src={ MoodleIcon } alt="logo moodle" />
              </a>
              : <div></div>
          }
          <div style={ { display: 'flex', } }>

            {/* commet to turn off  */}
            {/* <div className="snowBoxWrap">
              { isSnow ? <IconSnowflake color="#7671dd" /> : <IconSnowflakeOff color="#7671dd" /> }
              <InputCheckbox
                onClick={ isSnow ? () => dispatch(setSnowAnimation(false)) : () => dispatch(setSnowAnimation(true)) }
                label=""
              />
            </div> */}
            <p
              className="errorNotify"
              onClick={ () => setIsErrorPopup(!isErrorPopup) }
            >
              Сообщить об ошибке
              <IconAlertTriangle color={ 'red' } />
            </p>
            <HeaderNavCurrent />
            <div
              className="profileWrap"
              /* ref={anchorRef} */ onClick={
                open ? e => closePopup(e) : e => openPopup(e)
              }
            >
              {/* <img className="avatar" src="https://lk.tsutmb.ru/api/avatar" alt="avatar" /> */ }
              <img className="avatar" src={ EndPoints.avatar } alt="avatar" />
              { name }
              { open ? (
                <IconChevronUp
                  onClick={ e => openPopup(e) }
                  size={ 15 }
                  color="rgb(46, 46, 61)"
                />
              ) : (
                <IconChevronDown
                  /* onClick={(e) => openPopup(e)} */ size={ 15 }
                  color="rgb(46, 46, 61)"
                />
              ) }
            </div>
          </div>
        </div>
        <Popup
          isOpen={ isErrorPopup }
          handlePopup={ () => setIsErrorPopup(!isErrorPopup) }
        >
          <div className="errorWrap">
            <h3 className="errorTitle">Сообщить о проблеме</h3>
            <TextField
              placeHold="Введите тематику ошибки"
              value={ subject }
              handler={ setSubject } /* styles  */
            />

            <textarea
              placeholder="Опишите возникшую проблему"
              onChange={ e => setErrorValue(e?.target?.value) }
            />

            {/* <input type="tel" placeholder='Введите номер мобильного телефона' validate pattern="2[0-9]{3}-[0-9]{3}" onChange={(e) => setMobile(e.target.value)} className="justInput" style={{marginBottom: "30px"}}></input> */ }

            <PhoneInput
              defaultCountry="RU"
              placeholder="Введите номер мобильного телефона"
              value={ mobile }
              onChange={ setMobile }
            />

            <input
              className="PhoneInputInput"
              type="email"
              onChange={ e => setEmail(e.target.value) }
              placeholder="Введите адрес электронной почты"
            />

            {/* { !file ? (*/}
            {/*  <div className="fileWrapES" style={ { marginBottom: 20, } }>*/}
            {/*    <input*/}
            {/*      required*/}
            {/*      style={ { display: 'none', } }*/}
            {/*      type="file"*/}
            {/*      id="task"*/}
            {/*      onChange={ e => setFile([*/}
            {/*        e?.target?.files?.[0],*/}
            {/*      ]) }*/}
            {/*    />*/}
            {/*    <label htmlFor="task" className="dash">*/}
            {/*      Прикрепить файл*/}
            {/*      <IconPaperclip color="#7671DD" />*/}
            {/*    </label>*/}
            {/*  </div>*/}
            {/* ) : (*/}
            {/*  <div className="dash full">*/}
            {/*    <IconFile color="#7671DD" />*/}
            {/*    <p className="activity">*/}
            {/*      { file[0]?.name }*/}
            {/*      <span*/}
            {/*        style={ {*/}
            {/*          color: '#C5C5C5',*/}
            {/*          marginLeft: '10px',*/}
            {/*        } }*/}
            {/*      >{ `${ (file[0]?.size / 1000000).toFixed(2) }Мб` }</span>*/}
            {/*    </p>*/}
            {/*    <IconCircleX color="#C5C5C5" onClick={ () => setFile(null) } />*/}
            {/*  </div>*/}
            {/* ) }*/}
            <ButtonSimple
              variant="alone"
              active={ true }
              isDisabled={ isDisabled() }
              value="Сообщить"
              onClickHandler={ onSubmit }
            />
          </div>
        </Popup>

        <ProfilePopupCurrent
          anchorEl={ anchorEl }
          open={ open }
          closePopup={ closePopup }
        />

        <ImportantPopupMessage />

        <NewMobileAppVersionPopupMessage />
      </header>
    </>
  )
}

export default Header
