import { useState, useMemo, useEffect, } from 'react'
import PaperContainerWrapper from '../../../../common/PaperContainer/PaperContainerWrapper'
import TrackHeader from '../DetailPageComponents/HeaderBlock/TrackHeader'
import { attachedFiles, } from '../mockData/attachedFiles'
import DisciplineDetailCard from '../DisciplineDetailCard/DisciplineDetailCard'
import DisciplineResumeCard from '../DetailPageComponents/DisciplineResumeCard/DisciplineResumeCard'
import TrackStatistics from '../DetailPageComponents/TrackStatistics/TrackStatistics'
import StudentsDistribution from '../DetailPageComponents/StudentsDistribution/StudentsDistribution'
import Popup from '../../../../common/Popup/Popup'
import SectionWrapper from '../../../../common/SectionWrapper/SectionWrapper'
import RelatedDiscipline from '../DetailPageComponents/RelatedDiscipline/RelatedDiscipline'
import { useGetTrackDisciplines, } from '../../../../../gql/hooks/useMyEducation'
import { Preloader, } from '@/shared'
import { useGetTracks, } from '../../../../../gql/hooks/useMyEducation'
import { useSelector, } from 'react-redux'

const TrackDetailPage = ({
  role,
  type,
  id,
}: any) => {
  const [
    isPopupOpen,
    setIsPopupOpen,
  ] = useState(false)
  const [
    popupContent,
    setPopupContent,
  ] = useState(<></>)

  const { data, error, loading, } = useGetTrackDisciplines({
    filter: {
      track_id: Number(id),
    },
  })
  
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUserId = useSelector(state => state.auth.me.data.guid)
  const nameSpec = new URLSearchParams(location.search).get('namespec')
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studInfo = useSelector(state => ((role === 'student' || role === 'student-rakus') ? state.prof.studentInfo?.filter((i: any) => i.namespec === nameSpec) : false))

  const {
    department_name,
    eform,
    nameprof,
    namespec,
    course,
    group,
    elevel,
    recordbook_guid,
  }: any = studInfo ? studInfo[0] : {}

  const payload = {
    filter: null,
    pagination: {
      offset: 0,
      limit: 1000,
    },
    sort: [
      {
        column: 'is_visible',
        order: 'desc',
      },
      {
        column: 'created',
        order: 'desc',
      },
    ],
  }

  const params = {
    ...payload,
    filter:
    role === 'student' || role === 'student-rakus'
      ? {
        track_id: Number(id),
        student_id: currentUserId,
        namespec: namespec,
        department: department_name,
        nameprof: nameprof,
        group: group,
        course: course,
        eform: eform,
        is_moderator: false,
        elevel: elevel,
        recordbook: recordbook_guid
        }
      : { 
        track_id: Number(id), 
        is_moderator: true 
      },
    pagination: {
      'offset': 0,
      'limit': 1000,
    },
  }


  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const refresh = useSelector(state => state.myEd.updateTracks)
  const trackInfo = useGetTracks(params)
  
  useEffect(() => {
    trackInfo.refetch()
  }, [
    trackInfo,
    refresh,
  ])

  const detailTrackResume = useMemo(() => {
    if (trackInfo.error) {
      return <div>...error...</div>
    }
    if (trackInfo.loading) {
      return <Preloader />
    } else if (trackInfo.data) {
      const file = trackInfo.data?.esTrackList?.items[0]?.file

      return <>
        <SectionWrapper content={ <div>
          <h2 className="discipline-detail-card__title">Связанные дисциплины</h2>
          <div style={ {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          } }>
            { file?.associated.map((i: any, index: any) => <RelatedDiscipline key={ index } dis={ i } />
            ) }
          </div>
        </div> } />
        <SectionWrapper content={ <div>
          <h2 className="discipline-detail-card__title">Конечная цель трека</h2>
          <div style={ {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            fontSize: '16px',
          } }>
            { file.target }
          </div>
        </div> } />
        <PaperContainerWrapper>
          <DisciplineResumeCard items={ file.resume } />
        </PaperContainerWrapper>
      </>
    }
  }, [
    trackInfo,
  ])


  const trackDisciplines = useMemo(() => {
    if (error) {
      return <div>...error....</div>
    }
    if (loading) {
      return <Preloader />
    } else if (data) {
      return <>
        <PaperContainerWrapper
          styles={ {
            display: 'flex',
            flexDirection: 'column',
          } }
        >
          <TrackHeader role={ role } type={ type }
            attachedFiles={ attachedFiles } id={ id } disciplinesList={ data.esTrackDisciplines }
            res={ trackInfo?.data?.esTrackList?.items[0] } is_active={ trackInfo.data?.esTrackList?.items[0]?.is_active } is_signed={ trackInfo.data?.esTrackList?.items[0]?.is_signed } />
        </PaperContainerWrapper>

        { role !== 'osrv-moder' ? (
          <>
            { data.esTrackDisciplines.map((i: any, index: any) => <PaperContainerWrapper key={ index }>
              <DisciplineDetailCard setIsPopupOpen={ setIsPopupOpen } setPopupContent={ setPopupContent }
                key={ i.discipline_id } discipline={ i } />
            </PaperContainerWrapper>) }
            { detailTrackResume }
          </>
        ) : false }

        { role === 'osrv-moder' ? (
          <>
            <TrackStatistics type={ type } payloadType="miner" />

            <StudentsDistribution type={ type } track_id={ +id } disciplines={ data.esTrackDisciplines } />
          </>
        ) : false }
        <Popup
          isOpen={ isPopupOpen }
          handlePopup={ () => setIsPopupOpen(false) }
          styles={ { width: 'fit-content', } }
          wrapperStyle={ {
            width: 'fit-content',
            maxWidth: '50000px',
          } }
        >
          { popupContent }
        </Popup>

        {/*    {role === 'osrv-moder' && type === 'Курсы' ? (
            <EnrollStudents />
          ) : false} */}
      </>
    }
  }, [
    error,
    loading,
    data,
    role,
    type,
    id,
    trackInfo,
    detailTrackResume,
    isPopupOpen,
    popupContent,
  ])

  return trackDisciplines
}

export default TrackDetailPage
