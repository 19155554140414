/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import './UsersTable.less'
import TooltipTgu from '@/shared/ui/TooltipTgu/TooltipTgu'
import ButtonSimple from '../../../../common/ButtonSimple/ButtonSimple'
import { IconComponent, } from '../../../../common/ButtonSimple/ButtonSimple'
import { IconCirclePlus, IconCircleX, IconUsers, } from '@tabler/icons'

import React, { useEffect, useMemo, } from 'react'
import { Table, Form, } from 'antd'
import {
  useGetTrackStudents,
  useSignOutOneTrack,
  useSignOutTrack,
} from '../../../../../gql/hooks/useMyEducation'
import { Preloader, } from '@/shared'
import { useSelector, } from 'react-redux'
import { getSemester, } from '../../../../../Utils/functions'
import { IconTrash, } from '@tabler/icons'
import { useLocation, } from 'react-router-dom'
import { URL, } from '@/shared/constants/constants'
import { useAppContext, } from '@/app/providers/AppProvider'
import { TooltipPlacementVariant, } from '@/shared/ui/TooltipTgu/types'

const EditableContext = React.createContext(null)

const EditableRow = ({ index, ...props }: any) => {
  const [
    form,]:
    any = Form.useForm()
  return (
    <Form form={ form } component={ false }>
      <EditableContext.Provider value={ form }>
        <tr { ...props } />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
    ...restProps
}: any) => {
  const childNode = children

  return <td { ...restProps }>{ childNode }</td>
}

const UsersTable = ({
  isAddStudent,
  setIsAddStudent,
  track_id,
  disciplines,
}: any) => {
  const location = useLocation()

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const refresh = useSelector(state => state.myEd.trackStudents)
  const id = new URLSearchParams(location.search).get('id')
  const name = new URLSearchParams(location.search).get('name')

  const [
    esSignOutTrack,
  ] = useSignOutTrack()
  const [
    esSignOutTrackStudent,
  ] = useSignOutOneTrack()

  const { data, error, loading, refetch, } = useGetTrackStudents({
    track_id,
  })

  useEffect(() => {
    refetch()
  }, [
    refetch,
    refresh,
  ])

  const studentsTable = useMemo(() => {
    if (error) {
      return <div>Произошла ошибка, попробуйте позже</div>
    }
    if (loading) {
      return <Preloader />
    } else if (data) {
      const components = {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      }

      const columns = [
        {
          title: '№',
          dataIndex: 'number',
        },
        {
          title: 'ФИО',
          dataIndex: 'fullName',
        },
        {
          title: disciplines[0]?.discipline,
          dataIndex: 0,
        },
        {
          title: disciplines[1]?.discipline,
          dataIndex: 1,
        },
        {
          title: disciplines[2]?.discipline,
          dataIndex: 2,
        },
        {
          title: 'Отписать студента',
          dataIndex: 'signout',
        },
      ]

      const { addNotification, openToast, } = useAppContext()

      const handleSignOut = (semester: any) => {
        esSignOutTrack({
          variables: {
            i: {
              track_id,
              semester: 1,
            },
          },
        })
          .then(() => openToast?.({ message: 'Cтуденты отписанны', }))
          .catch(e => {
            console.log(e)
            openToast?.({ message: 'Произошла ошибка, попробуйте повторить позже', })
          })
      }

      const handleOneSignOut = (semester: any, student_id: any) => {
        esSignOutTrackStudent({
          variables: {
            i: {
              track_id,
              semester,
              student_id,
            },
          },
        })
          .then((res: any) => {
            addNotification?.(
              'Мое обучение',
              [
                student_id,
              ],
              {
                message: `Вас отписали от трека "${ name }". Пожалуйста, выберите другой трек.`,
              },
              { path: '/learning-pathway', }
            )
          })
          .then(() => openToast?.({ message: 'Cтуденты отписанны', }))
          .catch(e => {
            console.log(e)
            openToast?.({ message: 'Произошла ошибка, попробуйте повторить позже', })
          })
      }

      const downloadFile = () => {
        const link = document.createElement('a')
        link.href = `https://${ URL }/api/es/xls?type=miner&id=${ id }`
        /* link.download = name; */
        link.click()
      }

        const copiedData = [...data.esTrackStudents];

        // Sort the copied array
      const sortedData = copiedData.sort((a, b) => {
        if (a.full_name < b.full_name) {
          return -1;
        }
        if (a.full_name > b.full_name) {
          return 1;
        }
        return 0;
       });

      const dataSource = sortedData.map((i: any, index: any) => {
        return {
          key: index,
          number: index + 1,
          fullName: i.full_name,
          0: (
            <div
              style={ {
                display: 'flex',
                justifyContent: 'center',
              } }
            >
              <input
                type="checkbox"
                id="how-get"
                checked={ i?.disciplines?.includes(disciplines[0]?.discipline) }
                onChange={ () => console.log(1) }
              />
            </div>
          ),
          1: (
            <div
              style={ {
                display: 'flex',
                justifyContent: 'center',
              } }
            >
              <input
                type="checkbox"
                id="how-get"
                checked={ i?.disciplines?.includes(disciplines[1]?.discipline) }
                onChange={ () => console.log(1) }
              />
            </div>
          ),
          2: (
            <div
              style={ {
                display: 'flex',
                justifyContent: 'center',
              } }
            >
              <input
                type="checkbox"
                id="how-get"
                checked={ i?.disciplines?.includes(disciplines[2]?.discipline) }
                onChange={ () => console.log(1) }
              />
            </div>
          ),
          signout: (
            <TooltipTgu
              children={
                <IconTrash
                  color="rgb(255, 94, 94)"
                  onClick={ () => handleOneSignOut(
                    1,
                    i.student_id
                  )
                  }
                />
              }
              title="Отписать студента"
              placement={ TooltipPlacementVariant.bottom } />
          ),
        }
      })

      const locale = {
        emptyText: <div style={ { padding: '30px 0', } }>Нет данных</div>,
      }
      return (
        <>
          <Table
            locale={ locale }
            components={ components }
            rowClassName={ () => 'editable-row' }
            bordered
            dataSource={ dataSource }
            columns={ columns }
            pagination={ false }
          />
          <div className="discipline-detail-card__students-distribution-buttons-wrapper">
            <ButtonSimple
              value={
                <IconComponent text="Добавить студента" Icon={ IconCirclePlus } />
              }
              active
              onClickHandler={ () => setIsAddStudent(!isAddStudent) }
            />

            <ButtonSimple
              value={
                <IconComponent
                  text="Отписать всех"
                  Icon={ IconCircleX }
                  iconStyles={ { color: '#FF5E5E', } }
                />
              }
              variant="groupe"
              onClickHandler={ handleSignOut }
            />
            <ButtonSimple
              onClickHandler={ downloadFile }
              styles={ { marginLeft: '0.6rem', } }
              value={
                <IconComponent
                  text="Выгрузить список записанных студентов"
                  Icon={ IconUsers }
                />
              }
              variant="groupe"
            />
          </div>
        </>
      )
    }
  }, [
    data,
    error,
    loading,
    disciplines,
    isAddStudent,
    setIsAddStudent,
    esSignOutTrack,
    track_id,
  ])

  return (
    <div className="discipline-detail-card__students-distribution-users-table">
      { studentsTable }
    </div>
  )
}

export default UsersTable
